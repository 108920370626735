import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyTitle = lazy(
  () => import("./title-D-HRv048.js").then((module) => ({
    default: module.Title
  }))
);
function Title({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyTitle, { ...props });
}
const useTitleContent = () => {
  return useMemo(
    () => ({
      title: {
        component: Title
      }
    }),
    []
  );
};
export {
  LazyTitle as Title,
  useTitleContent
};
